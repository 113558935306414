import React from 'react';
import Header from './header';
import Footer from './footer';
import BotaoWhats from './BotaoWhats';

export default ({ children, location }) => (
  <div className="fundoHome">
    <Header location={location} />
    <div id="main">
      {children}
    </div>
    <Footer />

    <BotaoWhats />
  </div>
);
