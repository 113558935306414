import React from 'react';
import { withPrefix } from 'gatsby';
import { useIntl, Link } from 'gatsby-plugin-intl';

export default ({ location = {} }) => {
  const intl = useIntl();

  const classCurrentPathname = (pathnames = []) => (
    // add prefixo do site
    pathnames.map(a => withPrefix(a))
      // add opções com a barra no final mantendo o default
      .concat(pathnames.map(a => `${withPrefix(a)}/`))
      // add opções com o prefixo do idioma atual mantendo o default
      .concat(pathnames.map(a => withPrefix(`${intl.locale}${a}`)))
      .includes(location.pathname) ? 'current_page_item current-menu-item' : ''
  );

  return (
    <div className="menu-home-container">
      <ul id="menu-home" className="nav-menu">
        <li id="menu-item-85" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-home page_item page-item-24 menu-item-85 ${classCurrentPathname(['/'])}`}>
          <Link to="/" aria-current="page">{intl.formatMessage({ id: 'Home' })}</Link>
        </li>
        <li id="menu-item-84" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-84 ${classCurrentPathname(['/empresa'])}`}>
          <Link to="/empresa">{intl.formatMessage({ id: 'Empresa' })}</Link>
        </li>
        <li id="menu-item-86" className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-86 ${classCurrentPathname(['/catalogo-expresso-instalado', '/catalogo-expresso-mobile', '/catalogo-impresso', '/catalogo-personalizado', '/desenhos-e-ilustracoes-tecnicas'])}`}>
          {/* <a href="#">Produtos</a> */}
          <div className="ideia-btn btn-produtos" style={{ textTransform: 'uppercase' }}>{intl.formatMessage({ id: 'Produtos' })}</div>
          <ul className="sub-menu">
            <li id="menu-item-278" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-278 ${classCurrentPathname(['/catalogo-expresso-instalado'])}`}>
              <Link to="/catalogo-expresso-instalado">{intl.formatMessage({ id: 'Catálogo Expresso Instalado' })}</Link>
            </li>
            <li id="menu-item-281" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-281 ${classCurrentPathname(['/catalogo-expresso-mobile'])}`}>
              <Link to="/catalogo-expresso-mobile">{intl.formatMessage({ id: 'Catálogo Expresso Mobile' })}</Link>
            </li>
            <li id="menu-item-569" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-569 ${classCurrentPathname(['/catalogo-impresso'])}`}>
              <Link to="/catalogo-impresso">{intl.formatMessage({ id: 'Catálogo Impresso' })}</Link>
            </li>
            <li id="menu-item-287" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-287 ${classCurrentPathname(['/catalogo-personalizado'])}`}>
              <Link to="/catalogo-personalizado">{intl.formatMessage({ id: 'Catálogo Personalizado' })}</Link>
            </li>
            <li id="menu-item-287" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-287 previne-capitalize ${classCurrentPathname(['/desenhos-e-ilustracoes-tecnicas'])}`}>
              <Link to="/desenhos-e-ilustracoes-tecnicas">{intl.formatMessage({ id: 'Desenhos e Ilustrações Técnicas' })}</Link>
            </li>
          </ul>
        </li>
        <li id="menu-item-105" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-105">
          <a target="_blank" rel="noopener noreferrer" href="https://www.baixecatalogo.com.br">{intl.formatMessage({ id: 'Downloads' })}</a>
        </li>
        <li id="menu-item-126" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-126 ${classCurrentPathname(['/clientes'])}`}>
          <Link to="/clientes">{intl.formatMessage({ id: 'Clientes' })}</Link>
        </li>
        <li id="menu-item-132" className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-132 ${classCurrentPathname(['/contato'])}`}>
          <Link to="/contato">{intl.formatMessage({ id: 'Contato' })}</Link>
        </li>
      </ul>
    </div>
  );
};
