import React, { useState } from 'react';
import { useIntl, changeLocale } from 'gatsby-plugin-intl';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const StyledMenu = withStyles((theme) => ({
  paper: {
    [theme.breakpoints.up(990)]: {
      backgroundColor: '#444',
      color: '#fff',
    },
  },
}), { withTheme: true })((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const IDIOMAS = {
  pt: 'Português',
  en: 'English',
  es: 'Español',
};

export default () => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setIdioma = (idm) => {
    setAnchorEl(null);
    // altera linguagem do site
    changeLocale(idm);
  };

  return (
    <div className="col-xl-4 col-lg-5 col-md-12 text-right language lingua">
      <li id="transposh-2" className="widget widget_transposh">
        <span className="no_translate">
          <button type="button" onClick={handleClick}>
            {IDIOMAS[intl.locale] || IDIOMAS.pt}
            <i className="fas fa-chevron-down"></i>
          </button>

          <StyledMenu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => setIdioma('pt')}>Português</MenuItem>
            <MenuItem onClick={() => setIdioma('en')}>English</MenuItem>
            <MenuItem onClick={() => setIdioma('es')}>Español</MenuItem>
          </StyledMenu>
        </span>
      </li>
    </div>
  );
};
