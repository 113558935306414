export default ({ intl }) => [
  {
    titulo: intl.formatMessage({ id: 'Home' }),
    to: '/',
  },
  {
    titulo: intl.formatMessage({ id: 'Empresa' }),
    to: '/empresa',
  },
  {
    titulo: intl.formatMessage({ id: 'Catálogo Expresso Instalado' }),
    to: '/catalogo-expresso-instalado',
  },
  {
    titulo: intl.formatMessage({ id: 'Catálogo Expresso Mobile' }),
    to: '/catalogo-expresso-mobile',
  },
  {
    titulo: intl.formatMessage({ id: 'Catálogo Impresso' }),
    to: '/catalogo-impresso',
  },
  {
    titulo: intl.formatMessage({ id: 'Catálogo Personalizado' }),
    to: '/catalogo-personalizado',
  },
  {
    titulo: intl.formatMessage({ id: 'Desenhos e Ilustrações Técnicas' }),
    to: '/desenhos-e-ilustracoes-tecnicas',
  },
  {
    titulo: intl.formatMessage({ id: 'Clientes' }),
    to: '/clientes',
  },
  {
    titulo: intl.formatMessage({ id: 'Orçamento' }),
    to: '/orcamento',
  },
  {
    titulo: intl.formatMessage({ id: 'Contato' }),
    to: '/contato',
  }
]