import React from 'react';
import { isMobile } from 'react-device-detect';
import ImagemIconeWhats from '../../images/icone-whats-home.png';
import { openWhats } from '../../libs/util/utils';
import style from './style.module.scss';

const BotaoWhats = () => (
  <button onClick={() => openWhats('551129175277', isMobile)} className={style.containerBotaoWhats}>
    <img src={ImagemIconeWhats} alt="icone whats" />
  </button>
);

export default BotaoWhats;
