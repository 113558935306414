import React, { useState } from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import getConfigRotasMenu from '../../data/configRotasMenu';
import './style.scss';

export default () => {
  const intl = useIntl();

  const configRotasMenu = getConfigRotasMenu({ intl });

  const [drawerOpen, setDrawerOpen] = useState(false);

  const switchDrawerOpen = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen((a) => !a);
  };

  return (
    <>
      <div className="container-button-open-drawer">
        <Button
          className="button-open-drawer"
          onClick={switchDrawerOpen}
          onKeyDown={switchDrawerOpen}
          variant="contained"
          size="small"
        >
          <i className="fas fa-bars"></i>
        </Button>
      </div>
      <Drawer open={drawerOpen} onClose={switchDrawerOpen}>
        <List>
          {configRotasMenu.map((config) => (
            <Link to={config.to} key={config.to}>
              <ListItem button>
                {config.titulo}
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </>
  );
};
