export default ({ intl }) => ({
  institucional: [
    {
      nome: intl.formatMessage({ id: 'Home' }),
      enderecoTo: '/',
    },
    {
      nome: intl.formatMessage({ id: 'Empresa' }),
      enderecoTo: '/empresa',
    },
    {
      nome: intl.formatMessage({ id: 'Clientes' }),
      enderecoTo: '/clientes',
    },
    {
      nome: intl.formatMessage({ id: 'Orçamento' }),
      enderecoTo: '/orcamento',
    },
    {
      nome: intl.formatMessage({ id: 'Contato' }),
      enderecoTo: '/contato',
    },
  ],
  produtos: [
    {
      nome: intl.formatMessage({ id: 'Catálogo Expresso Instalado' }),
      enderecoTo: '/catalogo-expresso-instalado',
    },
    {
      nome: intl.formatMessage({ id: 'Catálogo Expresso Mobile' }),
      enderecoTo: '/catalogo-expresso-mobile',
    },
    {
      nome: intl.formatMessage({ id: 'Catálogo Impresso' }),
      enderecoTo: '/catalogo-impresso',
    },
    {
      nome: intl.formatMessage({ id: 'Catálogo Personalizado' }),
      enderecoTo: '/catalogo-personalizado',
    },
    {
      nome: intl.formatMessage({ id: 'Desenhos e Ilustrações Técnicas' }),
      enderecoTo: '/desenhos-e-ilustracoes-tecnicas',
      previneCapitalize: true,
    },
  ],
});
