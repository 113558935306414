import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';
import SelecaoIdioma from './SelecaoIdioma';
import config from '../data/config.json';
import getConfigNavegacoesFooter from '../data/configNavegacoesFooter';
import ImgLogoideiaBranco from '../images/logo-ideia-branco.png';


export default () => {
  const intl = useIntl();

  const configNavegacoesFooter = getConfigNavegacoesFooter({ intl });

  return (
    <footer role="contentinfo" className="fundoCinza">
      <div className="container container-footer">
        <div className="row">
          <div className="col-xl-2 col-lg-2 col-md-12 logoRodape">
            <img alt={intl.formatMessage({ id: 'Ideia 2001 Catálogos Inteligentes' })} src={ImgLogoideiaBranco} />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-12 lh-90">
            <i className="far fa-envelope mr-10"></i> {intl.formatMessage({ id: 'E-mail' })}: <a style={{ color: '#ffff' }} href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-12 lh-90 ">
            <i className="fas fa-phone mr-10"></i>{intl.formatMessage({ id: 'Telefone' })}: <a style={{ color: '#ffff' }} href={`tel:${config.telefoneUrl}`}>{config.telefone}</a>
          </div>
          <div className="col-lg-2 col-md-12 redesSociaisFooter lh-90">
            <a target="_blank" rel="noreferrer" title="Face Ideia 2001" aria-label="Face Ideia 2001" href={config.facebook}><i className="fab fa-facebook-square fa-xs ml-10"></i></a>
            <a target="_blank" rel="noreferrer" title="Instagram Ideia 2001" aria-label="Instagram Ideia 2001" href={config.instagram}><i className="fab fa-instagram"></i></a>
            <a target="_blank" rel="noreferrer" title="Linkedin Ideia 2001" aria-label="Linkedin Ideia 2001" href={config.linkedin}><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 linhaCinza"></div>
        </div>
        <div className="row menuRodape mt-5">
          <div className="col-lg-2 col-md-12">
            <strong className="tt-u mb-2">{intl.formatMessage({ id: 'Institucional' })}</strong>
            {configNavegacoesFooter && configNavegacoesFooter.institucional && configNavegacoesFooter.institucional.length ? (
              <div className="menu-institucional-rodape-container">
                <ul id="menu-institucional-rodape" className="nav-institucional">
                  {configNavegacoesFooter.institucional.map((config) => (
                    <li
                      key={config.nome}
                      id="menu-item-89"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-24 current_page_item menu-item-89"
                    >
                      {config.enderecoTo ? (
                        <Link to={config.enderecoTo} aria-current="page">{config.nome}</Link>
                      ) : null}
                      {config.endereco ? (
                        <a href={config.endereco} aria-current="page">{config.nome}</a>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="col-lg-3 col-md-12">
            <strong className="tt-u mb-2">{intl.formatMessage({ id: 'Produtos' })}</strong>
            {configNavegacoesFooter && configNavegacoesFooter.produtos && configNavegacoesFooter.produtos.length ? (
              <div className="menu-produtos-footer-container">
                <ul id="menu-produtos-footer" className="nav-rodape">
                  {configNavegacoesFooter.produtos.map((config) => (
                    <li
                      key={config.nome}
                      id="menu-item-89"
                      className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-24 current_page_item menu-item-89 ${config.previneCapitalize ? 'previne-capitalize' : ''}`}
                    >
                      {config.enderecoTo ? (
                        <Link to={config.enderecoTo} aria-current="page">{config.nome}</Link>
                      ) : null}
                      {config.endereco ? (
                        <a href={config.endereco} aria-current="page">{config.nome}</a>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <div className="col-lg-7 col-md-12 loca">
            <strong className="tt-u mb-2">{intl.formatMessage({ id: 'Localização' })}</strong>
            {intl.formatMessage({ id: 'Rua' })} <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Idéia+2001+Informática/@-23.6140449,-46.571296,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce5cece2f93775:0xcb66be63d8f4b6fc!8m2!3d-23.6140498!4d-46.5691073" className="enredeco-rodape">Amazonas, 363 - 09520-070 - São Caetano do Sul / SP - {intl.formatMessage({ id: 'Brasil' })}</a>
          </div>
        </div>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="direitos pb-2">
            {intl.formatMessage({ id: 'Ideia 2001 Catálogos Inteligentes' })}
          </div>
          <div className="container-selecao-idioma-mobile">
            <SelecaoIdioma />
          </div>
        </div>
      </div>
    </footer>
  );
};
