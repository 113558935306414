export const validaEmail = (email) => {
  const usuario = email.substring(0, email.indexOf('@'));
  const dominio = email.substring(email.indexOf('@') + 1, email.length);
  if ((usuario.length >= 1)
    && (dominio.length >= 3)
    && (usuario.search('@') === -1)
    && (dominio.search('@') === -1)
    && (usuario.search(' ') === -1)
    && (dominio.search(' ') === -1)
    && (dominio.search('.') !== -1)
    && (dominio.indexOf('.') >= 1)
    && (dominio.lastIndexOf('.') < dominio.length - 1)) {
    return true;
  }
  return false;
};

export const openWhats = (telefone, isMobile) => {
  const urlMobile = `whatsapp://send?phone=${telefone}`;
  const urlDesktop = `https://api.whatsapp.com/send?phone=${telefone}`;

  if (isMobile) window.open(urlMobile, '_blank');
  else window.open(urlDesktop, '_blank');
};
